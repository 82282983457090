<template>
    <div class="container mx-auto mt-6">

        <div
            class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-blue-500"
        >
            <div class="flex items-center justify-between">
                <a
                    href="/"
                >
                    Would you like to start a new devotional?
                </a>

                 <a
                     href="/"
                     class="bg-white text-gray-700 hover:text-gray-900 hover:bg-gray-200 px-5 py-2 rounded-md text-sm font-bold hidden sm:inline-block"
                 >
                    Buy new devotional
                </a>
            </div>
        </div>

        <subscriptions />
    </div>
</template>

<script>
import router from '../router';

export default {
    mounted() {
        if(this.$route.query.id) {
            this.$swal({
                titleText: 'You can start your new devotional now',
                text: 'By the way, you\'ll receive an email reminder every morning.',
                confirmButtonText: 'Start now',
                confirmButtonColor: 'rgb(4, 120, 87)',
                showCancelButton: true,
                cancelButtonText: 'Later',
                showCloseButton: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    router.push({
                        name: 'subscription',
                        params: {
                            id: this.$route.query.id,
                        },
                    })
                }
            })
        }
    },
};
</script>
