var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "grid grid-cols-1 md:grid-cols-4 gap-6 container mx-auto mt-8 sm:mt-10"
      },
      [
        _c("buy-button", {
          staticClass: "hidden md:block",
          attrs: { disabled: true, "series-slug": "matthew" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v("\n                Christmas\n            ")]
              },
              proxy: true
            },
            {
              key: "description",
              fn: function() {
                return [
                  _vm._v(
                    "\n                Discover the reason why so many people love Christmas! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.\n            "
                  )
                ]
              },
              proxy: true
            },
            {
              key: "button",
              fn: function() {
                return [_vm._v("\n                Coming Soon\n            ")]
              },
              proxy: true
            }
          ])
        }),
        _vm._v(" "),
        _c("buy-button", {
          attrs: { disabled: true, "series-slug": "matthew" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v("\n                Easter\n            ")]
              },
              proxy: true
            },
            {
              key: "description",
              fn: function() {
                return [
                  _vm._v(
                    "\n                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.\n            "
                  )
                ]
              },
              proxy: true
            },
            {
              key: "button",
              fn: function() {
                return [_vm._v("\n                Coming Soon\n            ")]
              },
              proxy: true
            }
          ])
        }),
        _vm._v(" "),
        _c("buy-button", {
          staticClass: "hidden md:block",
          attrs: { disabled: true, "series-slug": "matthew" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v("\n                Matthew\n            ")]
              },
              proxy: true
            },
            {
              key: "description",
              fn: function() {
                return [
                  _vm._v(
                    "\n                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis nostrud exercitation ullamco.\n            "
                  )
                ]
              },
              proxy: true
            },
            {
              key: "button",
              fn: function() {
                return [_vm._v("\n                Coming Soon\n            ")]
              },
              proxy: true
            }
          ])
        }),
        _vm._v(" "),
        _c("buy-button", {
          attrs: { disabled: false, "series-slug": "john" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v("\n                John\n            ")]
              },
              proxy: true
            },
            {
              key: "description",
              fn: function() {
                return [
                  _vm._v(
                    "\n                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis nostrud exercitation ullamco.\n            "
                  )
                ]
              },
              proxy: true
            },
            {
              key: "button",
              fn: function() {
                return [_vm._v("\n                Start for £10\n            ")]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container mx-auto mt-9 mb-9 text-center" },
      [
        _c(
          "h3",
          {
            staticClass: "text-6xl font-bold text-gray-700 leading-tight mb-4"
          },
          [_vm._v("\n            Family Bible times\n        ")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "text-1xl text-gray-600 mb-8" }, [
          _vm._v(
            "\n            The Bible is clear that parents are to be the primary passers-on of spiritual truth to their\n            children.\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "dark-button", attrs: { to: "/about" } },
          [_vm._v("\n            Why, When and How?\n        ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "grid grid-cols-1 md:grid-cols-5 gap-8 container mx-auto mt-8 sm:mt-10"
      },
      [
        _c("div", { staticClass: "col-span-3 mr-8 pr-6" }, [
          _c(
            "h1",
            { staticClass: "text-6xl lg:text-9xl font-bold leading-none mb-4" },
            [_vm._v("\n                Daily Slice of Life\n            ")]
          ),
          _vm._v(" "),
          _c(
            "h2",
            { staticClass: "sub-title text-4xl text-gray-900 leading-none" },
            [
              _vm._v(
                "\n                Helping parents and carers to provide their children with a daily slice of the Bread of Life.\n            "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-span-2 hidden md:block" }, [
          _c("img", {
            staticClass: "p-3",
            attrs: { src: "/images/bread-2.svg", alt: "Bread" }
          })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }