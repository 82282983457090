<template>
    <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Title
                                </th>
                                <th scope="col" class="hidden lg:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Status
                                </th>
                                <th scope="col" class="hidden lg:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Purchased
                                </th>
                                <th scope="col" class="relative px-6 py-3">
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="! rows" class="bg-white divide-y divide-gray-200">
                        <tr>
                            <td colspan="4" class="px-6 py-4 whitespace-nowrap font-medium text-center">
                                <p class="my-5">
                                    Loading...
                                </p>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-if="rows && rows.length" class="bg-white divide-y divide-gray-200">
                            <tr v-for="row in rows" :key="row.id">
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <router-link
                                        v-if="row.is_completed != 1"
                                        :to="{ name: 'subscription', params: { id: row.id }}"
                                        tag="a"
                                    >
                                        <div class="text-sm text-gray-900">
                                            {{ row.series.title }}
                                        </div>
                                        <div
                                            class="hidden lg:table-cell text-sm text-gray-500"
                                        >
                                            {{ row.series.sub_title }}
                                        </div>
                                    </router-link>

                                    <div
                                        v-if="row.is_completed == 1"
                                    >
                                        <div class="text-sm text-gray-900">
                                            {{ row.series.title }}
                                        </div>
                                        <div
                                            class="hidden lg:table-cell text-sm text-gray-500"
                                        >
                                            {{ row.series.sub_title }}
                                        </div>
                                    </div>
                                </td>
                                <td class="hidden lg:table-cell px-6 py-4 whitespace-nowrap">
                                    <span v-if="row.is_completed == 1" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                      Completed
                                    </span>
                                    <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-300 text-yellow-700">
                                      In Progress
                                    </span>
                                </td>
                                <td class="hidden lg:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ row.created_at | formatDate }}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <router-link
                                        v-if="row.is_completed != 1"
                                        :to="{ name: 'subscription', params: { id: row.id }}"
                                        tag="a"
                                        class="bg-gray-300 text-gray-800 hover:text-black hover:bg-gray-200 px-4 py-2 rounded-md text-sm font-bold"
                                    >
                                        View →
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="rows && ! rows.length" class="bg-white divide-y divide-gray-200">
                            <tr>
                                <td colspan="4" class="px-6 py-4 whitespace-nowrap font-medium text-center">
                                    <p class="my-5">
                                        Your devotionals will be listed here.
                                    </p>
                                    <p class="my-5">
                                        <a
                                            href="/"
                                            class="bg-gray-900 text-white hover:text-gray-100 hover:bg-gray-700 px-5 py-4 rounded-md text-sm font-bold"
                                        >
                                            Start here
                                        </a>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
const axios = require('axios');

export default {
    data: function() {
        return {
            rows: null,
        };
    },
    methods: {
        getRows() {
            axios.get('/api/subscriptions')
            .then((response) => {
                this.rows = response.data;
            });
        },
    },
    mounted: function () {
        this.getRows();
    },
    filters: {
        formatDate: (date) => {
            if (! date) {
                return null;
            }

            return dayjs(date).format('D MMM YYYY');
        }
    },
};
</script>
