<template>

    <FormulateForm
        @submit="submitForm"
        autocomplete="off"
        role="presentation"
        class="bg-white p-6 shadow rounded-lg flex flex-col w-full"
    >
        <h3 class="text-4xl font-bold text-gray-700 mb-3">
            Profile
        </h3>

        <div
            v-if="successMessage"
            class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-blue-500"
        >
            <span class="inline-block align-middle">
                {{ successMessage }}
            </span>
        </div>

        <div
            v-if="errors"
            class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500"
        >
            <div v-for="error in errors">
                <ul class="list-disc list-inside">
                    <li v-for="item in error">
                        {{ item }}
                    </li>
                </ul>
            </div>
        </div>

        <div class="w-full mb-6 md:w-1/2">
            <div class="relative">
                <FormulateInput
                    type="text"
                    readonly="readonly"
                    onfocus="this.removeAttribute('readonly');"
                    name="email"
                    label="New email address"
                    validation="optional|email"
                    v-model="formData.email"
                    input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-green-500 outline-none"
                />
            </div>
        </div>
        <div class="w-full mb-6 md:w-1/2">
            <div class="relative">
                <FormulateInput
                    autocomplete="new-password"
                    type="password"
                    name="password"
                    label="New password"
                    v-model="formData.password"
                    validation="optional|min:7,length"
                    input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-green-500 outline-none"
                />
            </div>
        </div>

        <button
            type="submit"
            class="bg-green-700 text-white hover:bg-green-600 px-4 py-2 rounded-lg"
            style="width: fit-content;"
        >
            <font-awesome-icon v-if="isSending" :icon="['fas', 'spinner']" spin class="mr-2" />
            Save
        </button>

    </FormulateForm>

</template>

<script>
const axios = require('axios');

export default {
    data: function() {
        return {
            isSending: false,
            formData: {
                email: '',
                password: '',
            },
            errors: null,
            successMessage: null,
        };
    },
    methods: {
        submitForm() {
            this.isSending = true;
            this.successMessage = null;
            this.errors = null;

            axios.patch('/api/profile', this.formData)
            .then((response) => {
                this.successMessage = response.data.message;
            })
            .catch((error) => {
                this.errors = error.response.data.errors;
            })
            .then(() => {
                this.isSending = false;
            });
        },
    },
};
</script>
