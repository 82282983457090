var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "container mx-auto mt-9" }, [
        _c("div", { staticClass: "grid grid-cols-1 md:grid-cols-3 gap-8" }, [
          _c(
            "h3",
            {
              staticClass:
                "text-6xl font-bold text-gray-700 leading-none mb-6 break-words"
            },
            [
              _vm._v(
                "\n                Family Bible times - Why?\n            "
              )
            ]
          ),
          _vm._v(" "),
          _c("div", [
            _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
              _vm._v(
                "\n                    The Bible is clear that parents are to be the primary passers-on of spiritual truth to their\n                    children\n                    (see Deuteronomy 4:9-10, 6:4-9, 6:20-23, 11:18-21, 32:46; Psalm 78:1-6; Proverbs 22:6, Ephesians\n                    6:1-4).\n                    That does not mean that they are the only people in a child's life responsible for their spiritual\n                    nurture, because grandparents and the local church also have a role to play. Still, parents are to\n                    take\n                    the lead and contribute the bulk of what their children know and understand about their Creator.\n                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
              _vm._v(
                "\n                    Deuteronomy 6 uses the word 'impress' to describe the process whereby parents pass on spiritual\n                    truth. That's\n                    a powerful word! It implies a deliberate action to create a lasting mark.\n                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
              _vm._v(
                "\n                    Studies by Barna Research showed that 85% of parents understand that this task is primarily\n                    their\n                    responsibility.\n                    But related studies also found that the vast majority of parents do not take any deliberate\n                    action\n                    to fulfil\n                    this role on a weekly, let alone daily basis.\n                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
              _c(
                "a",
                {
                  staticClass: "hover:text-gray-800",
                  attrs: {
                    href:
                      "https://www.barna.com/research/parents-accept-responsibility-for-their-childs-spiritual-development-but-struggle-with-effectiveness",
                    target: "_blank"
                  }
                },
                [
                  _vm._v(
                    "\n                        Read this helpful article from barna.com\n                    "
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
              _vm._v(
                "\n                    There may be several reasons for this; busyness, fatigue, lack of confidence in their own\n                    understanding or\n                    ability, or a misconception about what family Bible time looks like and how much effort will be\n                    required to do\n                    it and keep it going. If any of this resonates with you, then Daily Slice of Life is for you!\n                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
              _vm._v(
                "\n                    We aim to demystify the concept of family Bible time and provide you with a workable, guilt-free\n                    structure around\n                    which your family can flourish spiritually, and ongoing encouragement to keep doing it.\n                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
              _vm._v(
                "\n                    If you've ever observed a children's talk presented in a local church complete with props and\n                    backed\n                    by a\n                    theological degree, then be assured that is not what we're aiming for! Like any family that\n                    gathers\n                    to celebrate\n                    an event, when we meet as a local church, we enjoy a lovingly prepared feast from the word of\n                    God,\n                    but we don't\n                    have that feast every single day! For the rest of the week, we (and our children) survive\n                    because we\n                    eat\n                    smaller, less elaborate meals every day. Likewise, family Bible time doesn't need to be a\n                    complete\n                    Sunday school\n                    lesson or a children's talk, but merely a daily slice from the Bread of Life!\n                "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-1 md:grid-cols-3 gap-12 my-9" },
          [
            _c(
              "h3",
              {
                staticClass:
                  "text-6xl font-bold text-gray-700 leading-none mb-6 break-words"
              },
              [
                _vm._v(
                  "\n                Family Bible times - When?\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", [
              _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
                _vm._v(
                  "\n                    Each family has a unique rhythm which changes over time depending on the family dynamic and circumstances.\n                    Factors that will affect this rhythm include the number and age spread of children and the working hours of parents.\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
                _vm._v(
                  "\n                    If there is a large age gap between the children, especially when some are very young, then you might need to 'divide and conquer' with each of a couple taking responsibility for either the younger or older children. This phase doesn't last very long in most families but can be a more effective way to make the time meaningful, especially for younger children, without boring the older ones.\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
                _vm._v(
                  "\n                    Bedtime is generally good with very young children, but mealtimes work well when children are beyond the toddler stage. Breakfast is an option, but mornings are often frantic in most households, especially if you're also looking for a misplaced school shoe!\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
                _vm._v(
                  "\n                    The evening meal is usually more relaxed and an excellent opportunity for the family to connect. It's essential to prioritise eating at least one meal per day together if at all possible. Again, this will differ for each family and may change over time, depending on routines. The key is to find a time that works as part of your daily rhythm of a typical workday week (Monday to Friday for most).\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
                _vm._v(
                  "\n                    On a Saturday, you could do something different, like read an age-appropriate Christian biography or focus on a mission project. On Sunday, your entire family will have feasted on God's word with the rest of the church family, so it's unlikely that there will be much appetite for a slice of bread! But you could spend time discussing what you've all learned that day and pray it through. This resource will provide you with a Bible reading every weekday, a mission project to pray for on Saturdays and a persecuted church to pray for on Sundays. See 'Using this resource' for further details.\n                "
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-1 md:grid-cols-3 gap-12 my-9" },
          [
            _c(
              "h3",
              {
                staticClass:
                  "text-6xl font-bold text-gray-700 leading-none mb-4"
              },
              [_vm._v("\n                Using this resource\n            ")]
            ),
            _vm._v(" "),
            _c("div", [
              _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
                _vm._v(
                  "\n                    We have made our Christmas, Easter, and weekend resources freely available. There is a nominal £10 charge for every six month's worth of weekday sessions to help us cover costs.\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
                _vm._v(
                  "\n                    Once you have purchased a six-month block, every time you access the website on a weekday, you will be shown the next Bible reading in that particular sequence. On Saturdays, you will be shown a missionary story and on Sunday information about the persecuted church.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
                _vm._v(
                  "\n                    When you have completed a devotion, mark it as 'read'. If you miss a day, you will be shown that day's reading until you mark it as 'read'. The resources are not dated, so there is no need to play 'catch-up'. Remember, you are modelling a relationship, not religion to your children and like any relationship, if you forget to call a friend one day, you don't call them twice the next day!\n                "
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-1 md:grid-cols-3 gap-12 my-9" },
          [
            _c(
              "h3",
              {
                staticClass:
                  "text-6xl font-bold text-gray-700 leading-none mb-6 break-words"
              },
              [
                _vm._v(
                  "\n                Encouraging children to pray\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", [
              _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
                _vm._v(
                  "\n                    If Bible reading is the primary way in which God speaks to us, then prayer is how we talk to God. Like any conversation, it's helpful when the response to what someone has said is related to what they said! All too often, prayer time after Bible reading is unrelated to what God has just spoken through his word. Help your children understand the dynamic of our communication with God by encouraging prayers that relate to what you have all learned from the Bible passage. This does not mean that you don't pray for other things, but give priority to 'responsive' prayer which helps children to learn that we cannot respond rightly to God's word without his help.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("p", { staticClass: "text-1xl text-gray-500 mb-4" }, [
                _vm._v(
                  "\n                    For very young children you might suggest appropriate things to say before you pray. They will likely only repeat your words, and that's a good start. With older children talk briefly about what might be good things to say to God in response. Doing this gives everyone a chance to think about what they might say before they say it – even adults find this helpful!\n                "
                )
              ])
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }