var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "container mx-auto my-9" }, [
        _c("div", { staticClass: "grid grid-cols-1 md:grid-cols-3 gap-8" }, [
          _c(
            "h3",
            {
              staticClass:
                "col-span-1 text-6xl font-bold text-gray-700 leading-none mb-6 break-words"
            },
            [_vm._v("\n                Statement of Faith\n            ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-span-2" }, [
            _c("div", { staticClass: "container" }, [
              _c("h5", { staticClass: "sub-heading mb-3" }, [
                _vm._v(
                  "\n                        We firmly believe that:\n                    "
                )
              ]),
              _vm._v(" "),
              _c("ol", { staticClass: "list-disc list-inside" }, [
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    "\n                            There is one God in three persons, the Father, the Son and the Holy Spirit.\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    "\n                            God is sovereign in creation, revelation, redemption and final judgement.\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    "\n                            The Bible, as originally given, is the inspired and infallible Word of God. It is the supreme authority in all matters of belief and behaviour.\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    "\n                            Since the fall, the whole of humankind is sinful and guilty, so that everyone is subject to God's wrath and condemnation.\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    "\n                            The Lord Jesus Christ, God's incarnate Son, is fully God; he was born of a virgin; his humanity is real and sinless; he died on the cross, was raised bodily from death and is now reigning over heaven and earth.\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    "\n                            Sinful human beings are redeemed from the guilt, penalty and power of sin only through the sacrificial death once and for all time of their representative and substitute, Jesus Christ, the only mediator between them and God.\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    "\n                            Those who believe in Christ are pardoned all their sins and accepted in God's sight only because of the righteousness of Christ credited to them; this justification is God's act of undeserved mercy and grace, received solely by\n                            trust in him and not by their own efforts.\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    "\n                            The Holy Spirit alone makes the work of Christ effective to individual sinners, enabling them to turn to God from their sin and to trust in Jesus Christ.\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    "\n                            The Holy Spirit lives in all those he has regenerated. He makes them increasingly Christlike in character and behaviour and gives them power for their witness in the world.\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    "\n                            The one holy universal church is the Body of Christ, to which all true believers belong.\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    "\n                            The Lord Jesus Christ will return in person, to judge everyone, to execute God's just condemnation on those who have not repented and to receive the redeemed to eternal glory.\n                        "
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }