var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mx-auto mt-6" },
    [_vm._m(0), _vm._v(" "), _c("subscriptions")],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "text-white px-6 py-4 border-0 rounded relative mb-4 bg-blue-500"
      },
      [
        _c("div", { staticClass: "flex items-center justify-between" }, [
          _c("a", { attrs: { href: "/" } }, [
            _vm._v(
              "\n                Would you like to start a new devotional?\n            "
            )
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "bg-white text-gray-700 hover:text-gray-900 hover:bg-gray-200 px-5 py-2 rounded-md text-sm font-bold hidden sm:inline-block",
              attrs: { href: "/" }
            },
            [_vm._v("\n                Buy new devotional\n            ")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }