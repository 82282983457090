import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import Home from './views/Home';
import Dashboard from './views/Dashboard';
import Profile from './views/Profile';
import Subscription from './views/Subscription';
import About from './views/About';
import PrivacyPolicy from './views/PrivacyPolicy';
import StatementOfFaith from './views/StatementOfFaith';

function guardRoute(to, from, next) {
    if (! window.meta.isAuth) {
        window.location.href = '/login';
        return
    }
    next()
}

const router = new VueRouter({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: 'Home',
            },
        },
        {
            path: '/about',
            name: 'about',
            component: About,
            meta: {
                title: 'About',
            },
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: PrivacyPolicy,
            meta: {
                title: 'Privacy Policy',
            },
        },
        {
            path: '/statement-of-faith',
            name: 'statement-of-faith',
            component: StatementOfFaith,
            meta: {
                title: 'Statement of Faith',
            },
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                title: 'Dashboard',
            },
            beforeEnter: guardRoute,
        },
        {
            path: '/profile',
            name: 'profile',
            component: Profile,
            meta: {
                title: 'Profile',
            },
            beforeEnter: guardRoute,
        },
        {
            path: '/subscription/:id',
            name: 'subscription',
            component: Subscription,
            meta: {
                title: 'Subscription',
            },
            beforeEnter: guardRoute,
        },
    ]
});

export default router;
