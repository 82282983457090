var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "router-link",
        {
          staticClass: "light-button mb-3 md:mb-0 mr-0 md:mr-3",
          class: { "dark-button": this.$route.name === "home" },
          attrs: { to: "/", title: "Home" }
        },
        [_vm._v("\n        Home\n    ")]
      ),
      _vm._v(" "),
      !_vm.$meta.isAuth
        ? _c(
            "router-link",
            {
              staticClass: "light-button mb-3 md:mb-0 mr-0 md:mr-3",
              class: { "dark-button": this.$route.name === "about" },
              attrs: { to: "/about", title: "About" }
            },
            [_vm._v("\n        About\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.$meta.isAuth
        ? _c(
            "a",
            {
              staticClass: "light-button mb-3 md:mb-0 mr-0 md:mr-3",
              attrs: { href: "/login", title: "Login" }
            },
            [_vm._v("\n        Login\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.$meta.isAuth
        ? _c(
            "a",
            {
              staticClass: "light-button mb-3 md:mb-0 mr-0 md:mr-3",
              attrs: { href: "/register", title: "Register" }
            },
            [_vm._v("\n        Register\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$meta.isAuth
        ? _c(
            "router-link",
            {
              staticClass: "light-button mb-3 md:mb-0 mr-0 md:mr-3",
              class: { "dark-button": this.$route.name === "dashboard" },
              attrs: { to: "/dashboard", title: "Dashboard" }
            },
            [_vm._v("\n        Dashboard\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$meta.isAuth
        ? _c(
            "router-link",
            {
              staticClass: "light-button mb-3 md:mb-0 mr-0 md:mr-3",
              class: { "dark-button": this.$route.name === "profile" },
              attrs: { to: "/profile", title: "Profile" }
            },
            [_vm._v("\n        Profile\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$meta.isAuth
        ? _c(
            "a",
            {
              staticClass: "light-button mb-3 md:mb-0 mr-0 md:mr-3",
              attrs: { href: "/logout", title: "Logout" }
            },
            [_vm._v("\n        Logout\n    ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }