require('./bootstrap');

require('alpinejs');

window.Vue = require('vue');

// Global variables
Vue.prototype.$meta = window.meta;

// Vue Router
import Router from './router';

// App template
import App from './views/App';

// Toast
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
Vue.use(VueToast);

// Forms
import VueFormulate from '@braid/vue-formulate';
Vue.use(VueFormulate);

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExternalLinkAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faExternalLinkAlt, faSpinner);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Sweetalerts
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// Components
import BuyButton from './components/BuyButton.vue';
import Subscriptions from './components/Subscriptions.vue';
import ProfileForm from './components/ProfileForm.vue';
import Lesson from './components/Lesson.vue';

Vue.component('buy-button', BuyButton);
Vue.component('subscriptions', Subscriptions);
Vue.component('profile-form', ProfileForm);
Vue.component('lesson', Lesson);

new Vue({
    el: '#app',
    components: {
        App,
    },
    router: Router,
});
