<template>
    <div
        @click="getSessionToken"
        :class="{'pointer-events-none': isSending, 'cursor-pointer': ! disabled}"
        class="shadow w-full hover:shadow-md bg-white rounded-lg p-6 disabled:opacity-50"
    >
        <h3
            class="text-3xl font-bold leading-none mb-3"
            :class="{'text-gray-400': disabled, 'text-gray-700': ! disabled}"
        >
            <slot name="title" />
        </h3>

        <p class="text-1xl text-gray-600 mb-6">
            <slot name="description" />
        </p>

        <div
            class="w-full text-1xl text-center text-white p-3 rounded-lg font-bold"
            :class="{'bg-green-900': isSending, 'bg-gray-400': disabled, 'bg-green-700 hover:bg-green-600': ! disabled}"
        >
            <font-awesome-icon v-if="isSending" :icon="['fas', 'spinner']" spin class="mr-2" />
            <slot name="button" />
        </div>
    </div>
</template>

<script>
const axios = require('axios');
import '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

export default {
    props: {
        seriesSlug: String,
        disabled: Boolean,
    },
    data: function() {
        return {
            isSending: false,
        };
    },
    methods: {
        async getSessionToken() {
            if(this.disabled) {
                return
            }

            const stripe = await loadStripe(window.meta.stripeKey);

            this.isSending = true;

            axios.get('/api/stripe-session', {
                params: {
                    series: this.seriesSlug,
                },
            })
            .then(function (response) {
                if(response.data.url) {
                    // redirect if series is free
                    window.location.href = response.data.url;
                } else {
                    return stripe.redirectToCheckout({
                        sessionId: response.data.sessionId,
                    });
                }
            })
            .catch((error) => {
                this.isSending = false;

                if(error.response.statusText === 'Unauthorized') {
                    this.$swal({
                        titleText: 'Register or login first',
                        icon: 'warning',
                        confirmButtonText: 'Login',
                        confirmButtonColor: 'rgb(4, 120, 87)',
                        showCancelButton: true,
                        cancelButtonText: 'Register',
                        cancelButtonColor: 'rgb(4, 120, 87)',
                        showCloseButton: true,
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/login';
                        } else if (result.isDenied) {
                            window.location.href = '/register';
                        }
                    })
                }
            });
        },
    },
};
</script>
