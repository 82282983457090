var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-white p-6 shadow rounded-lg" }, [
    _c("h3", { staticClass: "text-1xl font-medium text-gray-300" }, [
      _vm.lesson === false
        ? _c("span", [_vm._v("\n            Loading...\n        ")])
        : _vm._e(),
      _vm._v(" "),
      _vm.lesson === null
        ? _c("span", [
            _vm._v("\n            No content is available today.\n        ")
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.lesson === null
      ? _c(
          "p",
          { staticClass: "mt-4" },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "bg-gray-300 text-gray-700 hover:text-gray-900 hover:bg-gray-200 px-4 py-2 rounded-md text-sm font-bold",
                attrs: { to: "/dashboard" }
              },
              [_vm._v("\n            Return to dashboard\n        ")]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.lesson
      ? _c("div", [
          _c("h3", { staticClass: "text-4xl font-bold text-gray-700 mb-1" }, [
            _vm._v("\n            " + _vm._s(_vm.lesson.title) + "\n        ")
          ]),
          _vm._v(" "),
          _vm.lesson.passage
            ? _c(
                "p",
                { staticClass: "uppercase font-bold text-gray-500 mb-5" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://www.biblegateway.com/passage/?search=" +
                          _vm.lesson.passage +
                          "&version=NIV",
                        target: "_blank",
                        title: "Read passage on Bible Gateway"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.lesson.passage) +
                          "\n                "
                      ),
                      _c("font-awesome-icon", {
                        staticClass: "text-gray-400",
                        attrs: { icon: "external-link-alt", size: "xs" }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("span", {
            attrs: { id: "lesson" },
            domProps: { innerHTML: _vm._s(_vm.lesson.content) }
          }),
          _vm._v(" "),
          _c("hr", { staticClass: "my-6" }),
          _vm._v(" "),
          _c("p", { staticClass: "mt-5 flex justify-between" }, [
            _c(
              "button",
              {
                staticClass:
                  "bg-green-600 text-white hover:bg-green-500 px-4 py-2 rounded-md text-sm font-bold mr-2 sm:mr-0",
                on: {
                  click: function($event) {
                    return _vm.skip(false)
                  }
                }
              },
              [_vm._v("\n                ✔ Mark as read\n            ")]
            ),
            _vm._v(" "),
            _vm.progress
              ? _c("span", { staticClass: "hidden sm:inline-block" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.progress.position) +
                      "\n                of\n                " +
                      _vm._s(_vm.progress.total) +
                      "\n            "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "bg-green-700 text-white hover:bg-green-600 px-4 py-2 rounded-md text-sm font-bold ml-2 sm:ml-0",
                on: {
                  click: function($event) {
                    return _vm.skip(true)
                  }
                }
              },
              [_vm._v("\n                → Skip to next\n            ")]
            )
          ]),
          _vm._v(" "),
          _vm.progress
            ? _c("p", { staticClass: "mt-5 text-center block sm:hidden" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.progress.position) +
                    "\n            of\n            " +
                    _vm._s(_vm.progress.total) +
                    "\n        "
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }