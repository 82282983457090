var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateForm",
    {
      staticClass: "bg-white p-6 shadow rounded-lg flex flex-col w-full",
      attrs: { autocomplete: "off", role: "presentation" },
      on: { submit: _vm.submitForm }
    },
    [
      _c("h3", { staticClass: "text-4xl font-bold text-gray-700 mb-3" }, [
        _vm._v("\n        Profile\n    ")
      ]),
      _vm._v(" "),
      _vm.successMessage
        ? _c(
            "div",
            {
              staticClass:
                "text-white px-6 py-4 border-0 rounded relative mb-4 bg-blue-500"
            },
            [
              _c("span", { staticClass: "inline-block align-middle" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.successMessage) + "\n        "
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.errors
        ? _c(
            "div",
            {
              staticClass:
                "text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500"
            },
            _vm._l(_vm.errors, function(error) {
              return _c("div", [
                _c(
                  "ul",
                  { staticClass: "list-disc list-inside" },
                  _vm._l(error, function(item) {
                    return _c("li", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item) +
                          "\n                "
                      )
                    ])
                  }),
                  0
                )
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "w-full mb-6 md:w-1/2" }, [
        _c(
          "div",
          { staticClass: "relative" },
          [
            _c("FormulateInput", {
              attrs: {
                type: "text",
                readonly: "readonly",
                onfocus: "this.removeAttribute('readonly');",
                name: "email",
                label: "New email address",
                validation: "optional|email",
                "input-class":
                  "w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-green-500 outline-none"
              },
              model: {
                value: _vm.formData.email,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "email", $$v)
                },
                expression: "formData.email"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-full mb-6 md:w-1/2" }, [
        _c(
          "div",
          { staticClass: "relative" },
          [
            _c("FormulateInput", {
              attrs: {
                autocomplete: "new-password",
                type: "password",
                name: "password",
                label: "New password",
                validation: "optional|min:7,length",
                "input-class":
                  "w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-green-500 outline-none"
              },
              model: {
                value: _vm.formData.password,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "password", $$v)
                },
                expression: "formData.password"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "bg-green-700 text-white hover:bg-green-600 px-4 py-2 rounded-lg",
          staticStyle: { width: "fit-content" },
          attrs: { type: "submit" }
        },
        [
          _vm.isSending
            ? _c("font-awesome-icon", {
                staticClass: "mr-2",
                attrs: { icon: ["fas", "spinner"], spin: "" }
              })
            : _vm._e(),
          _vm._v("\n        Save\n    ")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }