<template>
    <div class="app-wrapper">

        <nav>
            <div class="container mx-auto">
                <div class="bg-white rounded-lg px-4 sm:px-6 lg:px-6 mt-6">
                    <div class="flex items-center justify-between h-16">
                        <div class="flex flex-1">
                            <router-link
                                to="/"
                                class="text-gray-500 hover:text-gray-900 font-bold text-2xl"
                            >
                                Daily Slice of Life
                            </router-link>
                        </div>
                        <div class="hidden md:block">
                            <div class="flex items-baseline">
                                <NavigationItems />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="mobile-nav" class="block md:hidden pt-3">
                    <NavigationItems />
                </div>
            </div>
        </nav>

        <router-view />

        <Footer />

    </div>
</template>

<script>
import NavigationItems from '../components/NavigationItems.vue';
import Footer from '../components/Footer.vue';

export default {
    components: {
        NavigationItems,
        Footer,
    },
};
</script>
