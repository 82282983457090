var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "shadow w-full hover:shadow-md bg-white rounded-lg p-6 disabled:opacity-50",
      class: {
        "pointer-events-none": _vm.isSending,
        "cursor-pointer": !_vm.disabled
      },
      on: { click: _vm.getSessionToken }
    },
    [
      _c(
        "h3",
        {
          staticClass: "text-3xl font-bold leading-none mb-3",
          class: {
            "text-gray-400": _vm.disabled,
            "text-gray-700": !_vm.disabled
          }
        },
        [_vm._t("title")],
        2
      ),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "text-1xl text-gray-600 mb-6" },
        [_vm._t("description")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "w-full text-1xl text-center text-white p-3 rounded-lg font-bold",
          class: {
            "bg-green-900": _vm.isSending,
            "bg-gray-400": _vm.disabled,
            "bg-green-700 hover:bg-green-600": !_vm.disabled
          }
        },
        [
          _vm.isSending
            ? _c("font-awesome-icon", {
                staticClass: "mr-2",
                attrs: { icon: ["fas", "spinner"], spin: "" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("button")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }