<template>

    <div class="bg-white p-6 shadow rounded-lg">

        <h3 class="text-1xl font-medium text-gray-300">
            <span v-if="lesson === false">
                Loading...
            </span>
            <span v-if="lesson === null">
                No content is available today.
            </span>
        </h3>

        <p
            v-if="lesson === null"
            class="mt-4"
        >
            <router-link
                to="/dashboard"
                class="bg-gray-300 text-gray-700 hover:text-gray-900 hover:bg-gray-200 px-4 py-2 rounded-md text-sm font-bold"
            >
                Return to dashboard
            </router-link>
        </p>

        <div v-if="lesson">

            <h3 class="text-4xl font-bold text-gray-700 mb-1">
                {{ lesson.title }}
            </h3>

            <p
                v-if="lesson.passage"
                class="uppercase font-bold text-gray-500 mb-5"
            >
                <a
                    :href="`https://www.biblegateway.com/passage/?search=${lesson.passage}&version=NIV`"
                    target="_blank"
                    title="Read passage on Bible Gateway"
                >
                    {{ lesson.passage }}
                    <font-awesome-icon icon="external-link-alt" class="text-gray-400" size="xs" />
                </a>
            </p>

            <span id="lesson" v-html="lesson.content"></span>

            <hr class="my-6" />

            <p class="mt-5 flex justify-between">
                <button
                    @click="skip(false)"
                    class="bg-green-600 text-white hover:bg-green-500 px-4 py-2 rounded-md text-sm font-bold mr-2 sm:mr-0"
                >
                    ✔ Mark as read
                </button>

                <span
                    v-if="progress"
                    class="hidden sm:inline-block"
                >
                    {{ progress.position }}
                    of
                    {{ progress.total }}
                </span>

                <button
                    @click="skip(true)"
                    class="bg-green-700 text-white hover:bg-green-600 px-4 py-2 rounded-md text-sm font-bold ml-2 sm:ml-0"
                >
                    → Skip to next
                </button>
            </p>

            <p
                v-if="progress"
                class="mt-5 text-center block sm:hidden"
            >
                {{ progress.position }}
                of
                {{ progress.total }}
            </p>

        </div>

    </div>

</template>

<script>
const axios = require('axios');

export default {
    props: {
        subscription: [Number, String],
    },
    data: function() {
        return {
            lesson: false,
            progress: {},
        };
    },
    methods: {
        getLesson() {
            axios.get(`/api/subscriptions/${this.subscription}/lesson`)
            .then((response) => {
                this.lesson = response.data.lesson;
                this.progress = response.data.progress;
            });
        },
        skip(reload = false) {
            axios.post(`/api/subscriptions/${this.subscription}/skip`, {
                id: this.lesson.id,
            })
            .then((response) => {
                if(reload === false) {
                    Vue.$toast.open('Devotional marked as completed');
                }

                // reload with fetch new content
                if(reload === true) {
                    this.getLesson();
                }
            });
        },
    },
    mounted: function () {
        this.getLesson();
    },
};
</script>

<style lang="scss">
    #lesson
    {
        ul,
        ol
        {
            padding-left: 30px !important;
        }
        ul
        {
            list-style: disc !important;
        }
        ol
        {
            list-style: decimal !important;
        }
    }
</style>
