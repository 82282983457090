var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper" },
    [
      _c("nav", [
        _c("div", { staticClass: "container mx-auto" }, [
          _c(
            "div",
            { staticClass: "bg-white rounded-lg px-4 sm:px-6 lg:px-6 mt-6" },
            [
              _c(
                "div",
                { staticClass: "flex items-center justify-between h-16" },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-1" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "text-gray-500 hover:text-gray-900 font-bold text-2xl",
                          attrs: { to: "/" }
                        },
                        [
                          _vm._v(
                            "\n                            Daily Slice of Life\n                        "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "hidden md:block" }, [
                    _c(
                      "div",
                      { staticClass: "flex items-baseline" },
                      [_c("NavigationItems")],
                      1
                    )
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "block md:hidden pt-3",
              attrs: { id: "mobile-nav" }
            },
            [_c("NavigationItems")],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }