var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col" }, [
    _c("div", { staticClass: "-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }, [
      _c(
        "div",
        {
          staticClass:
            "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            },
            [
              _c(
                "table",
                { staticClass: "min-w-full divide-y divide-gray-200" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  !_vm.rows
                    ? _c(
                        "tbody",
                        { staticClass: "bg-white divide-y divide-gray-200" },
                        [_vm._m(1)]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.rows && _vm.rows.length
                    ? _c(
                        "tbody",
                        { staticClass: "bg-white divide-y divide-gray-200" },
                        _vm._l(_vm.rows, function(row) {
                          return _c("tr", { key: row.id }, [
                            _c(
                              "td",
                              { staticClass: "px-6 py-4 whitespace-nowrap" },
                              [
                                row.is_completed != 1
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "subscription",
                                            params: { id: row.id }
                                          },
                                          tag: "a"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "text-sm text-gray-900"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(row.series.title) +
                                                "\n                                    "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "hidden lg:table-cell text-sm text-gray-500"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(row.series.sub_title) +
                                                "\n                                    "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row.is_completed == 1
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-sm text-gray-900"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(row.series.title) +
                                              "\n                                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "hidden lg:table-cell text-sm text-gray-500"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(row.series.sub_title) +
                                              "\n                                    "
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "hidden lg:table-cell px-6 py-4 whitespace-nowrap"
                              },
                              [
                                row.is_completed == 1
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                  Completed\n                                "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-300 text-yellow-700"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                  In Progress\n                                "
                                        )
                                      ]
                                    )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "hidden lg:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm._f("formatDate")(row.created_at)
                                    ) +
                                    "\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                              },
                              [
                                row.is_completed != 1
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "bg-gray-300 text-gray-800 hover:text-black hover:bg-gray-200 px-4 py-2 rounded-md text-sm font-bold",
                                        attrs: {
                                          to: {
                                            name: "subscription",
                                            params: { id: row.id }
                                          },
                                          tag: "a"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    View →\n                                "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.rows && !_vm.rows.length
                    ? _c(
                        "tbody",
                        { staticClass: "bg-white divide-y divide-gray-200" },
                        [_vm._m(2)]
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "bg-gray-50" }, [
      _c("tr", [
        _c(
          "th",
          {
            staticClass:
              "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
            attrs: { scope: "col" }
          },
          [
            _vm._v(
              "\n                                Title\n                            "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "hidden lg:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
            attrs: { scope: "col" }
          },
          [
            _vm._v(
              "\n                                Status\n                            "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "hidden lg:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
            attrs: { scope: "col" }
          },
          [
            _vm._v(
              "\n                                Purchased\n                            "
            )
          ]
        ),
        _vm._v(" "),
        _c("th", { staticClass: "relative px-6 py-3", attrs: { scope: "col" } })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        {
          staticClass: "px-6 py-4 whitespace-nowrap font-medium text-center",
          attrs: { colspan: "4" }
        },
        [
          _c("p", { staticClass: "my-5" }, [
            _vm._v(
              "\n                                Loading...\n                            "
            )
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        {
          staticClass: "px-6 py-4 whitespace-nowrap font-medium text-center",
          attrs: { colspan: "4" }
        },
        [
          _c("p", { staticClass: "my-5" }, [
            _vm._v(
              "\n                                    Your devotionals will be listed here.\n                                "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "my-5" }, [
            _c(
              "a",
              {
                staticClass:
                  "bg-gray-900 text-white hover:text-gray-100 hover:bg-gray-700 px-5 py-4 rounded-md text-sm font-bold",
                attrs: { href: "/" }
              },
              [
                _vm._v(
                  "\n                                        Start here\n                                    "
                )
              ]
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }