var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mx-auto my-8 text-sm" }, [
    _c("div", { staticClass: "grid grid-cols-1 md:grid-cols-2" }, [
      _vm._m(0),
      _vm._v(" "),
      this.$route.name !== "subscription"
        ? _c(
            "p",
            { staticClass: "text-1xl text-gray-400 md:text-right" },
            [
              _c(
                "router-link",
                {
                  staticClass: "block sm:inline-block",
                  attrs: { to: "/privacy", title: "Privacy Policy" }
                },
                [_vm._v("\n                Privacy Policy\n            ")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "hidden sm:inline-block" }, [
                _vm._v("\n                |\n            ")
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "block sm:inline-block",
                  attrs: {
                    to: "/statement-of-faith",
                    title: "Statement of Faith"
                  }
                },
                [_vm._v("\n                Statement of Faith\n            ")]
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "text-1xl text-gray-400 text-left mb-3 md:mb-0" },
      [
        _c(
          "a",
          {
            staticClass: "block sm:inline-block",
            attrs: {
              href: "https://handcodedstudio.co.uk/",
              target: "_blank",
              title: "Website built by Hand Coded Studio"
            }
          },
          [
            _vm._v(
              "\n                Website built by Hand Coded Studio\n            "
            )
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "hidden sm:inline-block" }, [
          _vm._v("\n                |\n            ")
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "block sm:inline-block",
            attrs: {
              href: "https://www.vecteezy.com/free-vector/bread",
              target: "_blank"
            }
          },
          [
            _vm._v(
              "\n                Vector graphics by Vecteezy\n            "
            )
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }